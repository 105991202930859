import React from "react"
import { graphql } from "gatsby"
import PostLink from "../components/post-link"
import Layout from "../components/layout"
import { Link } from "gatsby"
import {Helmet} from "react-helmet";
import AdSense from 'react-adsense';


const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .filter(edge => edge.node.fileAbsolutePath.includes('/markdown/pages/'))
    .filter(edge => edge.node.frontmatter.path)
    .filter(edge => edge.node.frontmatter.path.includes('/news/'));
    //.map(edge => <PostLink key={edge.node.id} post={edge.node} />);
  
  return (
  <Layout>
  <Helmet>
    <title>Dietary Supplement Industry News and Consumer Alerts</title>
  </Helmet>
  <hr />
  <ul className="inline">
    { Posts.map(function(post, i) {
        return (
            <div><PostLink key={post.node.id} post={post.node} /></div>
        )
      }
      )}
    </ul>
  </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          fileAbsolutePath
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }

  }
`